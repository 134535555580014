*,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #181818;
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #606060;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  /* position: fixed;
  overflow: hidden; */
  top: 0;
  left: 0;
  right: 0;
}

@font-face {
  font-family: "youtube-bold";
  src: url("fonts/YouTubeSansDarkBold.ttf");
}

@font-face {
  font-family: "youtube-extra-bold";
  src: url("fonts/YouTubeSansDarkExtraBold.ttf");
}

@font-face {
  font-family: "youtube-light";
  src: url("fonts/YouTubeSansDarkLight.ttf");
}

@font-face {
  font-family: "youtube-medium";
  src: url("fonts/YouTubeSansDarkMedium.ttf");
}

:root {
  --background-color: #030303;
  --searchbar-input: #121212;
  --searchbar-color: #202020;
  --searchbar-border: #303030;

  /* --blue-dark: #001724;
  --blue-1: #072e42;
  --blue-2: #124a67;
  --blue-3: #10638d;
  --blue-4: #0e70a1;
  --blue-5: #b0c4ce; */

  /* --blue-dark: #001724;
  --blue-1: #072e42;
  --blue-2: #124a67;
  --blue-3: #10638d;
  --blue-4: #0e70a1;
  --blue-5: #b0c4ce; */
}

body {
  background-color: var(--background-color);
  /* background-color: var(--blue-2); */
}

#SearchPage {
  height: 100vh;
}

#searchSelect {
  margin-bottom: 1rem;
}

.searchBox {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0.22rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #dcdcdc;
  background-color: var(--searchbar-input);
  border: 1px solid var(--searchbar-border);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-results {
  position: relative;
  overflow-y: scroll;
  max-height: 636px;
  z-index: 150000;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.search-results::-webkit-scrollbar {
  display: none; /* Chrome and safari*/
}

#searchBar {
  position: fixed;
  width: 100%;
  z-index: 500;
  height: 60px;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
}

.navbar li > .active {
  color: white;
}

.navbar li {
  color: #818181;
  font-weight: bold;
  list-style-type: none;
  font-size: 1.2rem;
  margin-inline: 2em;
}

.navbar li:hover {
  color: white;
  cursor: pointer;
}

.navbar li a {
  color: #818181;
  text-decoration: none;
}

.navbar li a:hover {
  color: white;
  cursor: pointer;
}


#search-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  width: 100%;
  /* background-color: var(--searchbar-color); */
  /* background-color: #030303; */
}

#invite-nav {
  width: 100%;
  /* background-color: var(--searchbar-border); */
  background-color: #030303;
  color: white;
  text-align: center;
}

#invite-nav p {
  margin-bottom: 0;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  cursor: pointer;
}

.logo-icon {
  height: 1.8em;
}

.logo span {
  color: white;
  font-family: youtube-bold;
  font-size: 1.5em;
  margin-left: 0.2em;
  font-variant: no-contextual;
}

.account {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.7em;
  cursor: pointer;
}
.accountSettings{
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #212121;
  width: 300px;
  height: auto;
  right: 0;
  top: 2.6em;
  border: 1px solid #383838;
}
.accountSettings__userInfo{
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-weight: bold;
  border-bottom: 1px solid #383838;
}
.accountSettings__userInfo img{
  width: 40px !important;
  height: 40px !important;
  margin: 1em;
}
.accountSettings__ul{
  list-style-type: none;
  margin-top: 0.5em;
}
.accountSettings__ul li{
  color: white;
  padding: 0.6em 0em;
  font-size: 0.9em;
  display: block !important;
}
.accountSettings__ul li:hover{
  background-color: #383838;
}
.accountSettings__ul li > svg{
  margin-inline: 0.6em;
  color: #AAAAAA;
}

.account img {
  height: 1.6em;
  border-radius: 50px;
}

.closeIcon {
  color: white;
  font-size: 26px;
  cursor: pointer;
  margin-top: 0.15em;
  margin-right: 0.2em;
  position: absolute;
  right: 0;
}

.radioIcon {
  width: 1em;
  margin-top: -0.2em;
  margin-right: 0.2em;
}

.no-pointer {
  opacity: 0;
  pointer-events: none;
}

.searchBarInput {
  display: flex;
  position: relative;
  margin: 1em 0em;
}
.searchBtn {
  position: absolute;
  right: 0;
}

textarea:focus,
input:focus {
  outline: none;
}

.bottom-nav {
  display: flex;
  justify-content: space-between;
}
.inviteCode {
  color: white;
  font-weight: bold;
  font-size: clamp(1rem, 0.6vw, 1.8rem);
}

.inviteText {
  color: #777;
  font-weight: normal;
}

/* Video search results */
#videos {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  z-index: 6000;
}
.searchItem {
  display: flex;
  flex-direction: row;
  padding: 1em;
  cursor: pointer;
}
.searchItem img {
  height: clamp(15%, 0.6vw, 10%);
  width: clamp(15%, 0.6vw, 10%);
}
.searchInfo {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 0em 1em;
  /* font-size: calc(1vw + 0.5rem); */
  font-size: clamp(0.9rem, 0.6vw, 1.8rem);
}
.searchTitle {
  color: white;
  font-weight: bold;
}
.searchDisc {
  color: rgb(212, 212, 212);
}

#form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchBarInput {
  flex: 1;
}

#form svg {
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

/* token page */

.party-form {
  margin-top: 2em;
}

.party-form p {
  color: white;
  margin: 1em 0em;
}

/* .nieuw-playlist-btn {
  width: 100%;
} */
.join-party-btn {
  margin-right: 0.8em;
}

.token-input {
  display: inline-block;
  width: calc(88% / 6);
  height: 50px;
  line-height: 48px;
  text-align: center;
  font-size: 2em;
  color: #181819;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px 2px 0 0;
  transition: background-color 0.3s, color 0.3s, opacity 0.3s;
  cursor: default;
  margin: 0;
  margin-top: 10px;
  margin-right: 2%;
  padding: 0;
}

.token-input:focus {
  outline: 0;
  box-shadow: none;
  border-color: #dc3545;
  animation: border-pulsate 1.5s infinite;
  -webkit-tap-highlight-color: transparent;
}

.wrong,
.wrong:focus {
  box-shadow: none;
  background-color: #ffd6de;
  border-color: #dc3545;
  animation: border-pulsate 1.5s infinite;
  -webkit-tap-highlight-color: transparent;
}

.controls {
  margin-top: 1em;
}

.token-container {
  display: block;
  background: #fff;
  width: 320px;
  /* min-height: 400px; */
  border-radius: 3px;
  margin: 0 auto;
  padding: 30px;
  position: relative;
}

.token-container h1 {
  position: relative;
  display: inline-block;
  color: #000;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 20px;
  padding: 0;
}

.token-container h1:after {
  display: block;
  background: #dc3545;
  content: "";
  height: 3px;
  width: 50%;
  margin: 20px auto 0;
  padding: 0;
}

.most-recent-container {
  display: flex;
  background: #fff;
  width: 320px;
  border-radius: 3px;
  margin: 0 auto;
  padding: 30px 10px 30px 30px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  max-height: 5em;
  font-weight: bold;
  transition: all 0.2s ease;
  cursor: pointer;
}

.most-recent-container:hover {
  background-color: #dc3545;
  color: #fff;
}

.most-recent-container:hover > .playarrow {
  color: #fff;
}

.most-recent-container > .playarrow {
  color: #dc3545;
  font-size: 3em;
  float: right;
  transition: all 0.2s ease;
}

.token-content {
  display: block;
  text-align: center;
  margin: 10px 0;
}
.token-content label {
  margin-bottom: 1rem;
}

.token-content:before {
  display: table;
  content: "";
}

.token-content:after {
  clear: both;
}

.token-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;
  bottom: 2rem;
}

.token-buttons button {
  font-size: 1.3rem !important;
  margin: 0.5rem 0rem;
  padding: 0.6rem;
}

.token-buttons button:last-child {
  margin: 0;
  margin-top: 0.5rem;
}

.hidden {
  display: none !important;
  /* transition: all 0.9s ease-out 0s; */
}

.disabled {
  cursor: default;
}

.mainspinner {
  width: 4rem;
  height: 4rem;
  color: rgb(109, 109, 109);
  display: flex;
  margin: 0 auto;
}

.overlay {
  background-color: #030303;
  display: flex;
  width: 100%;
  justify-content: center;
  position: fixed;
  height: 100vh;
  flex-direction: column;
  transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  top: 0;
  z-index: 10001;
  pointer-events: none;
}
.spinner-border {
  animation: 1s linear infinite spinner-border;
}

input[type="text"]:disabled {
  background-color: white !important;
}

.nav-icons {
  display: none !important;
}

.controller {
  width: 100%;
  height: 72px;
  background-color: rgb(33, 33, 33);
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  transition: 0.3s all ease-in-out;
}

.controller__left {
  color: white;
  flex-shrink: 0;
}

.controller__left--sideControls {
  margin: 1em;
  font-size: 24px !important;
  cursor: pointer;
}

.controller__left--mainControls svg {
  font-size: 40px !important;
  cursor: pointer;
  color: white;
}

.controller__middle {
  color: white;
  display: flex;
  align-items: center;
}

.controller__middle img {
  height: 40px;
}

.controller__middle--info {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-inline: 1em;
  line-height: 1.3;
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.controller__middle--info span:nth-child(2) {
  color: rgb(180, 180, 180);
}

.controller__middle--button {
  margin-right: 24px;
  display: block;
}
.controller__middle--button > svg {
  color: rgb(144, 144, 144);
}

.controller__middle--button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.MuiIconButton-root:hover {
  background-color: rgba(109, 109, 109, 0.2) !important;
}
.controller__right {
  display: flex;
  align-items: center;
}

.controller__right--onhover {
  display: flex;
  align-items: center;
}

.controller__right--slider {
  margin-right: 20px;
  margin-top: 6px;
}

.controller__right--slider > .MuiSlider-colorPrimary {
  color: white;
}
.controller__right--slider input:hover {
  box-shadow: 0px 0px 0px 8px rgb(214 214 214 / 16%) !important;
}
.controller__right--button {
  margin-right: 24px;
  color: rgb(144, 144, 144);
  cursor: pointer;
}

.controller__right--arrow {
  margin-right: 24px;
  color: rgb(144, 144, 144);
  cursor: pointer;
  display: none !important;
}

.homespinner {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
  color: white;
}

.deleteBtn {
  color: rgb(65, 65, 65) !important;
  background: rgba(19, 19, 19, 0.719) !important;
}

/* videoRow */
.videoRow {
  display: flex;
  margin-bottom: 30px;
  max-width: 800px;
}

.videoRow_text > h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 0.4em;
  margin-block-end: 0.4em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: white;
}
.videoRow > img {
  object-fit: contain;
  width: 246px;
  height: 138px;
}
.videoRow_text {
  margin-left: 14px;
}
.videoRow_headline {
  font-size: 0.8rem;
  color: #606060;
}
.videoRow_subs {
  background-color: #d3d3d3;
  padding: 2px;
}
.videoRow_subsNumber {
  font-weight: bolder;
  color: #549dd3;
}

/* queue page */
.fix-nav {
  padding-top: 80px;
}

#media-player {
  display: flex;
  flex-direction: row;
}
.yt-player {
  margin: 3.2em 0em;
  position: relative;
  width: 100%;
  height: 0;
  flex: 4;
}
#album {
  margin: 2rem 5rem;
  position: relative;
  width: 100%;
  height: 0;
  flex: 4;
}
#album-player {
  width: 100%;
  /* background-color: var(--blue-1);
  padding: 3em 2em;
  max-width: 440px;
  margin: 0 auto; */
  height: 100vh;
}
#player {
  /* height: 169px; */
  aspect-ratio: 16/9;
  /* padding-top: 56.25%; */
  width: 100%;
  display: block !important;
}
.album-photo {
  justify-content: center;
  display: flex;
}

.album-photo-border {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* margin: 0.6em 1em 0.6em 0.6em; */
  height: 12em;
  width: 12em;
  overflow: hidden;
  text-align: center;
}
.album-photo-border img {
  /* width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px; */
  min-width: 100%;
  max-width: none;
  margin: 0 -100%;
}
.album-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.2em;
}
.album-details .title-items {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.album-details .title-items svg {
  cursor: pointer;
}
.album-details .title {
  color: white;
  font-size: 30px;
  font-weight: bold;
  padding: 0 2em;
  text-align: center;
}
.album-details .artist {
  color: var(--blue-5);
  margin-top: 0.5em;
}
input[type="range"] {
  margin: auto;
  outline: none;
  padding: 0;
  width: 640px;
  height: 2px;
  background-color: #777;
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, #ffffff),
    color-stop(100%, #ffffff)
  );
  background-size: 0% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  box-shadow: none;
  border: none;
  background: transparent;
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 5px;
  /*16x16px adjusted to be same as 14x14px on moz*/
  height: 11px;
  width: 11px;
  border-radius: 5px;
  background: #e7e7e7;
  border: 1px solid #c5c5c5;
}

.slider input {
  width: 100%;
  margin-top: 1.2em;
}
.slider .timestamps {
  color: var(--blue-5);
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
}
.control-buttons {
  display: flex;
  justify-content: space-around;
  align-self: center;
  margin-top: 0.5em;
}
.control-buttons svg {
  color: white;
  cursor: pointer;
  transform: scale(1.5);
  align-self: center;
}
.pause {
  background-color: var(--blue-2);
  padding: 1.5em;
  border-radius: 50px;
  cursor: pointer;
}
.pause:hover {
  background-color: var(--blue-3);
}
.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 550px;
  min-height: 400px;
}
#queue-buttons {
  display: flex;
  width: 100%;
  font-size: 0.8em;
  /* background-color: var(--blue-2); */
  background-color: #030303;
  color: #4d4d4d;
}
.queue-btn {
  padding-top: calc(1.6em / 2);
  padding-bottom: calc(2.5em / 2);
  width: 100%;
  /* height: 3em; */
  border: 0;
  text-transform: uppercase;
  /* font-weight: 600; */
  background: none;
  color: var(--blue-5);
  transition: margin 0.5s ease-in-out;
  border-bottom: 2px solid var(--blue-4);
}

#queue-buttons .active {
  border-bottom: 2px solid white;
  color: white;
}

#queue nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--blue-2);
  padding-top: 0.5em;
}

.queue-balk {
  width: 50px;
  border-radius: 25px;
  height: 6px;
  content: "";
  background-color: #367da1;
  z-index: 100;
  cursor: pointer;
  display: none;
}

#queue {
  /* background-color: rgb(134, 134, 134); */
  flex: 2.5 1;
  width: auto;
  /* height: 100%; */
  margin: 2rem 5rem 2rem 0;
  /* background-color: black; */
  display: block;
  /* top: calc(calc(100vh - calc(100vh - 100%)) - 59px);
  z-index: 5000; */
  justify-content: space-between;
  /* padding-top: 40px; */
  /* background-color: var(--blue-2); */
}

#queue-list p {
  color: white;
}

#queue-list-title {
  color: rgb(134, 134, 134);
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

.queue-song {
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  align-items: center;
  /* padding: 0.6em 1.5em 0.6em 1.5em; */
  /* padding: 0.6em; */
  height: 60px;
  font-size: 0.8em;
  cursor: move;
  background-color: #030303;
  -webkit-box-shadow: inset 0 -5px 5px -5px var(--searchbar-border);
  -moz-box-shadow: inset 0 -5px 5px -5px var(--searchbar-border);
  box-shadow: inset 0 -5px 5px -5px var(--searchbar-border);
  padding: 0 0 0 0.6em;
  overflow: hidden;
}

.queue-options{
  display: flex;
  justify-content: space-between;
  padding: 1em;
  align-items: center;
  background-color: var(--background-color)
}
.queue-options__details{
  display: flex;
  flex-direction: column;
}

.queue-options__details span:nth-child(1){
  color: white;
  font-weight: bold;
}

.queue-options__details span{
  color: #9A9A9A;
  font-size: 0.9em;
}
.MuiSwitch-track{
  background-color: gray !important;
}
.css-ny5bw0-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #1976d2 !important;
}
/* .queue-song:hover {
  background-color: var(--searchbar-color);
  background-color: var(--blue-3);
  border-top: 1px solid var(--searchbar-border);

  -webkit-box-shadow: inset 0 -5px 5px -5px var(--blue-1);
  -moz-box-shadow: inset 0 -5px 5px -5px var(--blue-1);
  box-shadow: inset 0 -5px 5px -5px var(--blue-1);
} */
.queue-items {
  position: relative;
  overflow-y: scroll;
  max-height: 700px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.queue-items::-webkit-scrollbar {
  display: none;
}

.song-display,
.song-display:hover {
  background: var(--blue-1) !important;
}

.song-display .details-right svg {
  color: white;
  margin: 0.5em;
}

.details-left {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* margin: 0.6em 1em 0.6em 0.6em; */
  height: 3em;
  width: 3em;
  overflow: hidden;
  text-align: center;
  flex-shrink: 0;
  /* margin-right: 1.5em; */
}

.details-left img {
  /* min-width: 100%;
  max-width: none;
  margin: 0 -100%; */
  width: 32px;
  /* height: 32px; */
  flex: 1;
  flex-shrink: unset;
}

.currently-playing {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
}

.current-equalizer {
  opacity: 1 !important;
}

.equalizer {
  opacity: 0;
  position: absolute;
}

.selected {
  background-color: #1d1d1d;
}

.queue-song-index {
  color: white;
  padding: 1em;
}
.playlistBox {
  margin-bottom: 5em;
}

.playlistBox__info {
  display: flex;
  flex-direction: row;
  position: relative;
}

.playlistBox__info img {
  width: 64px;
  height: 64px;
  border-radius: 50px;
  margin-right: 1em;
}

.playlistItem {
  display: flex;
  flex-direction: column;
  margin-right: 2em;
  color: white;
  font-weight: bold;
  position: relative;
  transition: all 300ms ease;
}

.playlistItem:hover .playlistItem__overlay {
  display: block;
}
.playlistItem__overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 226px;
  height: 226px;
  background: linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%);
}
.playlistItem__overlay:hover {
  cursor: pointer;
}
.playlistItem__overlay--arrow {
  color: white !important;
  background-color: #000000b3 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute !important;
  bottom: 1em;
  right: 1em;
  transition: background-color 300ms ease;
}
.playlistItem__overlay--arrow:hover {
  background-color: black !important;
  cursor: pointer;
  zoom: 1.1;
  bottom: 0.8em;
  right: 0.85em;
}
.playlistInfo {
  display: none;
}

.playlistItem span:nth-child(3) {
  margin-top: 1em;
  width: fit-content;
}
.playlistItem span:nth-child(3):hover {
  text-decoration: underline;
  cursor: pointer;
}

.playlistItem span:nth-child(4) {
  color: #aaaaaa;
}

.playlistItem__imageContainer {
  display: flex;
  justify-content: center;
  width: 226px;
  height: 226px;
  overflow: hidden;
  align-items: center;
  border-radius: 6px;
}

.playlistItem__imageContainer:hover {
  cursor: pointer;
}

.playlistItem__imageContainer img {
  zoom: 1.35;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderBtn {
  height: 40px;
  z-index: 100;
  background-color: white !important;
  color: black !important;
}
.sliderBtn:hover {
  background-color: white !important;
}
.sliderBtn:nth-child(1) {
  margin-right: -20px;
  margin-top: -80px;
  opacity: 0;
  transition: opacity 300ms ease;
}
.sliderBtn:nth-child(3) {
  margin-left: -20px;
  margin-top: -80px;
  transition: opacity 300ms ease;
}

.playlistContainer {
  display: flex;
  overflow-y: hidden;
  width: 100%;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  margin-top: 5em;
}

.infoContainer__left img {
  width: 246px;
  height: 246px;
  border-radius: 5px;
}
.infoContainer__right {
  margin-left: 3em;
  margin-top: 2em;
  display: flex;
  flex-direction: column;
}
.infoContainer__title {
  color: white;
  font-weight: bold;
}

.infoContainer__data,
.infoContainer__disc {
  color: #ffffffb3;
}

.infoContainer__disc {
  margin-top: 1em;
}

.infoContainer__right--buttons {
  margin-top: 1em;
}

.infoContainer__right--buttons button {
  padding-inline: 1.5em;
  padding-block: 0.5em;
  border-radius: 5px;
  border: 1px solid white;
  margin-right: 1em;
  font-weight: bold;
  font-size: 0.8em;
  background-color: rgb(0, 0, 0);
  color: white;
}
.infoContainer__right--buttons button:nth-child(1) {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.right__buttons--morevert {
  color: white;
  cursor: pointer;
}

.playlistTable {
  width: 100%;
  margin-top: 4em;
  margin-bottom: 5em;
}

.scrollbox {
  overflow: scroll;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

.tableItem {
  width: 100%;
  height: 45px;
  /* background-color: white; */
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #1d1d1d;
}

.tableItem__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0em 0em 0em 1.5em;
  color: #909090;
  font-weight: 550;
}

.tableItem__title {
  color: white;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableItem__disc {
  text-align: left;
  flex: 1;
}

.tableItem img {
  width: 32px;
  height: 32px;
}

iframe {
  z-index: 1000;
}

.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.login {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 400px;
  background-color: var(--blue-1);
  align-items: center;
  justify-content: center;
}

.loginBtn{
  color: black;
  background-color: white;
  border: 0;
  border-radius: 2px;
  font-size: 0.9em;
  font-weight: bold;
  padding: 0.3em 1em;
  cursor: pointer;
  flex-shrink: 0;
}

.login__googleButton {
  padding: 0.8em 1.2em;
  background-color: white;
  color: rgb(144, 144, 144);
  display: flex;
  align-items: center;
}

.login__logo {
  width: 150px;
  height: 150px;
}

.logo__title {
  color: white;
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 1em;
}

.login__googleLogo {
  width: 26px;
  height: 26px;
  margin-right: 1em;
}

.details {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
}
.details svg {
  margin-inline: 0.5em;
  font-size: 1.5em;
}

.details-inner {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 10;

}

.details-title {
  color: white;
  /* font-weight: 600; */
  font-size: 1.2em;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.details-artist {
  color: #909090;
  font-size: 1.2em;
  font-weight: 500;
  /* color: #b0c4ce; */
}

.details-right {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: right;

  justify-content: right;
  align-items: center;
  margin-left: 1em;
  flex: 1;
  color: #888;
  font-weight: bold;
  flex-shrink: 0;
}

.details-btn > svg {
  display: none;
  font-size: 24px;
}

.details-timestamp {
  font-size: 1.2em;
}

.queue-song:hover .details-btn > svg {
  display: inline-block !important;
  color: #888;
}

.queue-song:hover .details-btn {
  margin-right: 0.5em;
}

.queue-song:hover .details-timestamp {
  display: none;
}

.details-btn:hover,
.details-btn:active {
  background-color: var(--blue-4);
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* aspect ration for 16:9 */
  /*padding-top: 20px;*/ /* you can add padding-top if needed */
  height: 0;
  overflow: hidden;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lyrics-text {
  color: white;
  padding: 1em;
  font-size: 2em;
}

#audio-player {
  background: var(--blue-1);
  width: 100%;
}

.viewport-hidden {
  display: none;
}

.switch-button-container {
  display: flex;
  margin-bottom: 1.2em;
  justify-content: center;
}

.switch-button-container svg {
  display: none;
}

.switch-button {
  background: var(--blue-dark);
  border-radius: 30px;
  overflow: hidden;
  width: 140px;
  text-align: center;
  font-size: 0.8em;
  letter-spacing: 1px;
  color: white;
  position: relative;
  padding-right: 70px;
  position: relative;
  /* zoom: 0.7; */
}
.switch-button:before {
  content: "Video";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}
.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(70px);
  transition: transform 300ms ease;
}
.switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 0.5em 0;
  display: block;
  user-select: none;
  pointer-events: none;
}
.switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: var(--blue-2);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 30px;
  transform: translateX(0);
  transition: transform 300ms ease;
}
.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}

.browser-search {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  /* z-index: 9000; */
  margin: 0em;
}

ol, ul {
  padding-left: 0rem
;
}

.homepage{
  margin-top: 8em;
}

.homebackground{
  background-image:
  linear-gradient(to bottom, rgba(0, 0, 0, 0.62), #030303),
  url('images/homebackground.png');
  position: absolute;
  height: 70vh;
  overflow: hidden;
  width: 100%;
  background-size: cover;
  z-index: -1;
}

.searchItem {
  /* background-color: var(--blue-2); */
  background-color: #030303;
}

#searchPopup {
  display: none;
  z-index: 6000;
}
/* media queries */
@media only screen and (max-width: 1600px) {
  #album {
    margin: 0rem 5rem;
  }
  #queue {
    margin: 0rem 0rem;
  }
  .homebackground{
    height: 60vh;
  }
  .homepage {
    margin-top: 5em;
  }
}
@media only screen and (max-width: 1500px) {
  #album {
    margin: 0rem 4rem;
  }
  #queue {
    margin: 0rem 0rem;
  }
}
@media only screen and (max-width: 1400px) {
  #album {
    margin: 0rem 3rem;
  }
  #queue {
    margin: 0rem 0rem;
  }
  .homebackground{
    height: 50vh;
  }
}
@media only screen and (max-width: 975px) {
  #queue {
    /* margin-top: 10em !important; */
    z-index: 1000;
  }
  /* html,
  body {
    position: fixed;
    overflow: hidden;
  } */
  #album {
    position: relative !important;
    margin-bottom: 56.25% !important;
    display: block !important;
  }
  #invite-nav {
    background-color: var(--blue-dark);
  }
  .logo span {
    display: none;
  }
  #album-player {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 500px;
    height: 80vh;
  }
  #current-video {
    display: none;
  }

  /* .fix-nav {
    padding: 0;
  } */
  .viewport-hidden {
    display: block;
    /* aspect-ratio: 16/9; */
    padding-top: 56.25%;
  }
  .queue-balk {
    display: block;
  }
  #media-player {
    flex-direction: column;
  }
  #album {
    margin: 0;
    padding: 0;
    /* aspect-ratio: 16/9; */
    position: fixed;
  }
  #queue-list-title {
    padding: 1em;
  }
  .details-btn {
    margin: 0.4em 0em 0.4em 0.4em;
  }
  .details-inner{
    width: 70vw;
  }
  #album-player {
    padding: 1em 2em;
  }
  .album-details {
    margin-top: 0.5em;
  }
  .control-buttons {
    margin-top: 0;
  }
  .switch-button-container {
    justify-content: space-between;
  }
  .switch-button-container svg {
    display: flex;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
  }
  #searchPopup header {
    display: block !important;
  }
  #searchPopup #search-nav {
    background-color: var(--blue-1) !important;
  }
  #searchPopup .searchBox {
    background-color: var(--blue-dark);
    border-color: var(--blue-2);
  }
  #searchPopup .container-sm {
    display: flex;
    align-items: center;
    padding-left: 0;
  }
  #searchPopup form {
    align-items: center;
    flex: 10;
  }
  #searchPopup #search-nav svg {
    cursor: pointer;
  }
  #searchPopup .fix-nav {
    padding-top: 90px !important;
  }
  .details-btn {
    margin-right: -1.5em;
  }
  #searchPopup {
    margin: 0;
    padding: 0;
    width: 100%;
    position: fixed;
    transition: 0.6s all ease-in-out;
    top: calc(100vh - (100vh - 100%));
    height: 100vh;
    background: var(--blue-2);
    display: block;
    transition: 0.6s all ease-in-out;
  }
  .playlistItem__imageContainer {
    width: 160px !important;
    height: 160px !important;
  }
  .playlistItem__imageContainer img {
    width: 160px !important;
    height: 160px !important;
  }
  .playlistBox__info img {
    width: 50px;
    height: 50px;
  }
  .playlistBox__info > div > h2 {
    font-size: 1.2em;
  }
  .playlistBox__info > div > span {
    font-size: 0.8em !important;
  }
  .playlistBox__info > div > h2 > svg {
    font-size: 1em !important;
  }
  .playlistBox {
    margin-bottom: 2em;
  }
  .playlistItem__overlay {
    width: 160px;
    height: 160px;
  }
  .queue-items {
    max-height: calc(50vh + -50px);
  }
  .login {
    width: 100%;
    height: 100vh;
  }
  .wrapper {
    margin-top: 0;
  }

  .controller {
    height: 64px;
  }
  .controller__left--sideControls {
    margin: 5px;
  }
  .controller__middle img {
    display: none;
  }

  .controller__right--onhover {
    display: none;
  }
  .controller__right--arrow {
    display: block !important;
  }
  .controller__right--arrow:hover .controller__right--onhover {
    background-color: green;
    display: block;
  }
  .yt-player {
    position: relative;
    width: 100%;
    height: 0;
    flex: 1;
    margin: 0;
  }

  .infoContainer__title {
    font-size: 1.5em;
  }
  .infoContainer__data,
  .infoContainer__disc {
    font-size: 0.8em;
  }

  .navbar {
    justify-content: space-evenly;
  }

  .navbar li {
    font-size: 0;
  }

  .nav-icons {
    display: block !important;
  }
  .tokenli {
    font-size: 1rem !important;
  }
  .controller__middle--button {
    display: none !important;
  }
}
@media only screen and (max-width: 615px) {
  .bottom-nav {
    flex-direction: column;
  }

  #videos {
    padding-top: 159px;
  }
  #queue {
    margin: 0em 0em;
  }
}
